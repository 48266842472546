export function isFavorite(key, id) {
  const absoluteKey = 'favorites' + key;
  if (localStorage.getItem(absoluteKey)) {
    const ids = JSON.parse(localStorage.getItem(absoluteKey));
    for (var i = 0; i < ids.length; i++) {
      if (ids[i] === id) {
        return true;
      }
    }
  }
  return false;
}

export function toggleFavorite(key, id) {
  const absoluteKey = 'favorites' + key;
  if (localStorage.getItem(absoluteKey)) {
    const ids = JSON.parse(localStorage.getItem(absoluteKey));
    let adding = true;
    for (var i = 0; i < ids.length; i++) {
      if (ids[i] === id) {
        ids.splice(i, 1);
        adding = false;
        break;
      }
    }
    if (adding) {
      ids.push(id);
    }
    localStorage.setItem(absoluteKey, JSON.stringify(ids));
  } else {
    localStorage.setItem(absoluteKey, JSON.stringify([id]));
  }
}

export function getFavorites(key) {
  const absoluteKey = 'favorites' + key;
  if (localStorage.getItem(absoluteKey)) {
    return JSON.parse(localStorage.getItem(absoluteKey));
  }
}
